import './App.css';
import React, { useState } from 'react';
import RsvpModal from './RsvpModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import photo1 from './img/photo1.jpg';
import photo2 from './img/photo2.jpg';
import photo3 from './img/photo3.jpg';

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };



  return (
    <div className="App">
      <ToastContainer
        autoClose={8000}
        closeButton={false}
        limit={1}
        style={{ width: "400px" }}
      />
      <RsvpModal title="RSVP" modalIsOpen={modalIsOpen} closeModal={closeModal} />
      <header className="App-header bg">
        <h2>The wedding of</h2>
        <h1>Jordan & Jessica</h1>
        <h3>4.12.2025</h3>

        <button
          onClick={openModal}
          className="Rsvp-button"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          RSVP Here
        </button>
      </header>
      <div className="App-content-green-article article">
        <div> <img id='photo-one' src={photo1} alt='photoone'></img></div>

        <div id='story-text'>
          <h1>A whirlwind romance</h1>
          <p>We met the old fashion way, via the internet</p>
        </div>

      </div>
      <div className="App-content-grey article">
        <div id='info-text'>
          <h1>Join us for an intimate ceremony</h1>
          <h4>Blockhouse</h4>
          <p>1988 NW 18th Ave, Portland, OR 97209</p>
        </div>
        <div> <img id='photo-two' src={photo2} alt='phototwo'></img></div>
      </div>
      <div className="App-content-green-transparency article">
        <h1>We are excited to see you there</h1>
        <p>We are registered&nbsp;
          <a target='_blank' href='https://www.westelm.com/registry/sxzvhlt66h/registry-list.html' rel="noreferrer">here</a>
          &nbsp;or you can donate to our honeymoon <a target='_blank' href='https://www.venmo.com/u/Jordan-Maze' rel="noreferrer">here</a>
        </p>

        <button
          onClick={openModal}
          className="Rsvp-button"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          RSVP Before 3/8/2025
        </button>
      </div>
      <div> <img id='photo-three' src={photo3} alt='photothree'></img></div>
    </div>
  );
}

export default App;
