import './RsvpModal.css';
import { useState } from 'react';
import Modal from 'react-modal';
import { MultiSelect } from 'react-multi-select-component';
import axios from 'axios';
import { toast } from 'react-toastify';

const RsvpModal = ({ modalIsOpen, closeModal, title }) => {
  const [selected, setSelected] = useState([]);
  const [rsvpToggle, setRsvpToggle] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [text, setText] = useState('');
  const [isTouched, setIsTouched] = useState(false);



  const handleChangeRadio = (event) => {
    setIsTouched(true);
    setRsvpToggle(event.target.value)
  };

  const handleChangeText = (event) => {
    setIsTouched(true);
    setText(event.target.value)
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeInput = event => {
    setIsTouched(true);
    if (!isValidEmail(event.target.value)) {
      setCanSubmit(false);
      setError('Email is invalid');
    } else {
      setError(null);
      setCanSubmit(true);
    }

    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsTouched(true);

    try {
      const formData = {
        name: text,
        email: email,
        message: `${selected.map((obj) => obj.label).toString()}, ${rsvpToggle}. Message: ${text}. Email: ${email}`,
        subject: 'RSVP For Wedding'
      }
      const response = await axios.post('https://m4dgo1qgxc.execute-api.us-west-2.amazonaws.com/main/send-email',
        formData);

      const data = response.data;
      console.log(data)

      if (response.status >= 200 && response.status < 300) {
        toast.success('Your RSVP is sent. You will receive a confirmation soon.', {
          position: "top-center"
        })
      } else {
        toast.info(`Something went wrong, it's on us, please try again`, {
          position: "top-center"
        })
      }

    } catch (error) {
      toast.error('Something went wrong. Please try again', {
        position: "top-center",
      })
      console.error('Error:', error);
    }
  }

  let myData = [
    { value: '1', label: 'John' },
    { value: '2', label: 'Miles' },
    { value: '3', label: 'Charles' },
    { value: '4', label: 'Herbie' },
  ];

  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      portalclassName="modal"
    >
      <div className='modal-header'>
        <h1>{title}</h1>
      </div>
      <div className='modal-header'>
        <h3 className="sub-title">Steps to complete your RSVP</h3>
      </div>
      <div className='instructions'>
        <div className='contentBody'>
          <h4>1) Find the name(s) on your invitation</h4>
        </div>
        <div className='contentBody'>
          <h4>2) Select the name(s) below</h4>
        </div>
        <div className='contentBody'>
          <h4>3) Select if you are going to attend or decline</h4>
        </div>
        <div className='contentBody'>
          <h4>4) Click the "Submit" button at the bottom of this window</h4>
        </div>
      </div>
      <div className='modal-header input-title'>
        <h3>You will recieve a confirmation email in the next 24 hours</h3>
      </div>
      <div className='modal-header'>
        <div className='filter'>
          <MultiSelect
            classNamePrefix='filter'
            options={myData}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        </div>

        <div className='error-message'>
          {selected.length === 0 && isTouched ? `Nothing Selected` : null}
        </div>

      </div>

      <div className='modal-header'>
        <div className='modal-content'>
          <input onChange={handleChangeRadio}
            checked={rsvpToggle === 'Accepts with pleasure'}
            type="radio"
            value="Accepts with pleasure"
            name="rsvpA" /> Accepts with pleasure.
        </div>
        <div className='modal-content'>
          <input onChange={handleChangeRadio}
            checked={rsvpToggle === 'Declines with regret'}
            type="radio"
            value="Declines with regret" name="rsvpD" /> Declines with regret.
        </div>
        <div className='error-message'>
          {!rsvpToggle && isTouched ? `Please Select One` : null}
        </div>
      </div>
      <div className='modal-header input-title'>
        <h3>Leave us your email so we can confirm your selection(s)</h3>
      </div>
      <div className='modal-header'>
        <div className='rmsc'>
          <input
            className='email input-email'
            id="email"
            name="email"
            value={email}
            onChange={handleChangeInput}
            placeholder='Email'
          />
          <div className='error-message'>
            {error !== null && isTouched ? `${error}` : null}
          </div>
        </div>
      </div>
      <div className='modal-header input-title'>
        <h3>Leave a message for the Bride and Groom</h3>
      </div>
      <div className='modal-header'>
        <div className='rmsc'>
          <textarea
            className='rmsc-textarea'
            placeholder='Message'
            value={text}
            onChange={handleChangeText}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button className='Rsvp-button' onClick={closeModal}>Close</button>
        <button disabled={!canSubmit} className='Rsvp-button' onClick={handleSubmit}>Submit</button>
      </div>
    </Modal>
  );
}

export default RsvpModal;
